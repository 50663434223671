
<template>
	<div class="container theme-showcase" role="main">

		<div class="jumbotron" style="background-color: #F8F2FD">
		
			<h2><span class="glyphicon glyphicon-refresh " aria-hidden="true"></span> Интернет</h2>
			<h3>в Дом-А (ул. Шмидта, 56 ) </h3>
			<h4>скорость до 1 Гбит/c, отсутствует сложность при выборе тарифа </h4>
			
			


			
		</div>

		<div class="jumbotron " style="background-color: #F6FAFA" >
			<h3><span class="glyphicon glyphicon-home " aria-hidden="true"></span> Ваш дом подключен на {{percentOfMaxApartments}}%
				(<input style=" padding: 6px 8px; border-radius: 6px; margin-left: 6px; border: 1px solid #dadada;" v-model="countOfConnectedApartments" :min="0" :max="maxCountOfApartments" type="number" class="field-kw "> квартир)
			</h3>
			Проверьте как будет изменяться тариф для вас при изменении к-ва пользователей<br >
			* данные обновляются ежедневно
			
		</div>
		<div class="progress " @click="clickBar" >
			<div class="progress-bar progress-bar-info"
				 role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"
				  :style="barStyle"><span
				class="sr-only">{{countOfConnectedApartments}} кварты Подключено</span></div>
		</div>

		<div class="jumbotron " style="background-color: #FCF5F7">
			
			<h1 > <span class="glyphicon glyphicon-rub " aria-hidden="true"></span> {{aftercomiss}} рублей/мес.</h1>
			
			<p class="lead">Абонентская плата при текущем количестве пользователей.</p>
			<p class="lead"><strong>Данная сумма включает в себя:</strong></p> 
			<ul>
			<li><strong>{{unfondcom}} руб.</strong> - 1.2% комиссия в универсальный фонд</li>
			<li><strong>{{banccom}} руб.</strong> - {{bank}}% коммисия банка за услугу эквайринг</li> 
			<li><strong>{{tsjcom}} руб.</strong> - оплата в ТСЖ по договору обслуживания</li>
			<li><strong>{{obslugvolscom}} руб.</strong> - оплата за обслуживание оптической линии</li>
			<li><strong>{{zatratstroycom}} руб.</strong> - затраты на строительство линий связи в доме</li>
			<li><strong>{{ezerdi}} руб.</strong> - оплата инженерам, биллингу и ЦОДу</li>
			<li><strong>{{traftratcom}} руб.</strong> - оплата за интернет трафик крупным провайдерам и точкам обмена.</li>
			</ul>
			
			<small>В начале каждого месяца цена пересчитывается</small>
			
		</div>
		<div class="jumbotron" style="background-color: #F2F7FF" >
		<h2 ><span class="glyphicon glyphicon-thumbs-up " aria-hidden="true"></span> Технология и условия</h2>
		<p class="lead">
		<strong>2000 рублей </strong> - стоимость подключения <br />
		Технология: <strong>Ethernet</strong><br />
		Медная пара пропускной способностью до 1 Гб/c <br />
		<strong>Нет ограничений по скорости</strong><br />
		<strong>Нет ограничений по трафику</strong><br />
		Используйте любой роутер с wan rj45.<br />
		Оплачивайте услуги банковской картой, или оплатой по qr-коду<br />
		 <br />
				
		</p>
		
		<div align="center"><img src="/img/model-4-ezerdi1.png" class="img-responsive img-rounded"></div> <br />
		</div>
		
		<div class="jumbotron" style="background-color: #FEFAF6">
		<h2><span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Дополнительные услуги </h2>
		<p class="lead">
		Белый статический ipv4 адрес: <strong>100 рублей/мес. </strong>. <br />
		Поддержка ipv6 <br />
		Аренда роутера: 100 рублей/мес. <br />
		
		Аренда wi-fi удлинителя для роутера: 150 рублей/мес. <br />
		
		Цифровое телевидение: Смотрешка, 24h.tv  - зависит от выбранного пакета.
				
		</p>
		<h2><div align="center">Продажа оборудования:</div></h2>
		<div align="center"><img src="/img/vert-device.png" class="img-responsive img-rounded"> </div><br />
		</div>
		
		
		
	</div>
</template>

<script>

export default {
	name: 'App',
	data: () => ({
		countOfConnectedApartments: 4,   // кол-во квартир
		maxCountOfApartments: 200,        // полное кол-во квартир в доме
		testbest: 1, 
		avpotreb: 3, //среднее потребление скорости интернета в доме
		bank: 4.4, //комиссия банка экваринга
		unfond: 1.2, //универсальный фонд
		tsj: 500, //УКС или ТСЖ
		zatratstroy: 2100, //капитальные затраты
		obslugvols: 2000, //обслуживание ВОЛС
		ezerdi: 200, // оплата инженерам, биллингу
		//trafcost: 35,// стоимость трафика за мегабит
		//traftrat: 32000,
		
		
		
	}),
	computed: {
		/**
		 * Процент подключённых квартир от максимального числа квартир в доме
		 * @returns {number}
		 */
		percentOfMaxApartments() {
			return ( this.countOfConnectedApartments / this.maxCountOfApartments * 100 ).toFixed(1);
		},
		/**
		 * Объект стиля для прогресс-бара
		 */
		barStyle() {
			return {width: this.percentOfMaxApartments + '%'}
		},
		/**
		 * Ежемесячная плата за интернет
		 */
		payPerMonth() {
			return (2000.0 / this.countOfConnectedApartments).toFixed(0);
		},
		comission() {
			return parseFloat( (this.bank + this.unfond).toFixed(6) );
		},
		
		trafcost()	{
			
			if (this.bandwithall < 500 ) {
				
				return  (35) ;
			
			}
			
			if ( (this.bandwithall >= 500 ) &&  (this.bandwithall <= 1000 ) ) {
				
				return  (30) ;
			
			}
			
			if ( (this.bandwithall >= 1000 ) &&  (this.bandwithall <= 2000 ) ) {
				
				return  (22) ;
			
			}
			
			if ( (this.bandwithall >= 2000 ) &&  (this.bandwithall <= 10000 ) ) {
				
				return  (19) ;
			
			}
			
			return 35;
					
		},
		
		
		traftrat() {
			return (this.countOfConnectedApartments * this.trafcost * this.avpotreb);
		},

		
		costdocomis() {
			return ((this.traftrat + this.zatratstroy + this.tsj + this.obslugvols)/this.countOfConnectedApartments + this.ezerdi);
		},
		aftercomiss() {
			return parseFloat( (this.costdocomis * 100) / this.comissioncoef).toFixed(0);
		},
		
		banccom() {
			return parseFloat( (this.aftercomiss * this.bank / 100.00).toFixed(1));
		},
		
		unfondcom() {
			return parseFloat( (this.aftercomiss * this.unfond / 100.00).toFixed(1));
		},
		
		tsjcom() {
			return parseFloat( (this.tsj / this.countOfConnectedApartments ).toFixed(0));
		},
		
		obslugvolscom() {
			return parseFloat( (this.obslugvols / this.countOfConnectedApartments ).toFixed(0));
		},
		zatratstroycom() {
			return parseFloat( (this.zatratstroy / this.countOfConnectedApartments ).toFixed(0));
		},

		
		
		bandwithall() {
			return ( this.countOfConnectedApartments * this.avpotreb );
		},
		
		
		

		
		traftratcom() {
			return ( this.traftrat / this.countOfConnectedApartments ).toFixed(0);
		},
		
		comissioncoef() {
			return  (100.0 - this.comission) ;
		}
	},

	methods: {
		clickBar(event) {
			// console.log('clickBar: ', event);
			var rect = event.currentTarget.getBoundingClientRect();
			var x = event.clientX - rect.left; //x position within the element.
			var y = event.clientY - rect.top;  //y position within the element.
			let percentX = x / rect.width;

			this.countOfConnectedApartments = (this.maxCountOfApartments * percentX).toFixed(0);
			// console.log('rect: ', rect)
			// console.log("Left? : " + x + " ; Top? : " + y + ".");
			console.log('target: ', event.target)
			console.log('event:', event)
			console.log('this: ', this)
		}
	}
}
</script>

<style lang="scss">
.field-kw {
	width: 3em;
}
.progress {
	cursor: pointer;
}
</style>
